/*eslint-disable */
let BaseURL = process.env.VUE_APP_API_BASE_URL;
let root = {
authLoginApiUrl: {
  "link": BaseURL + "api/account/consult/login/"
},
authLoginVerifyApiUrl: {
  "link": BaseURL + "api/account/consult/login-verify/"
},
authSignupApiUrl: {
  "link": BaseURL + "api/account/consult/signup/"
},
authSignupVerifyApiUrl: {
  "link": BaseURL + "api/account/consult/signup-verify/"
},
selfUserInfoApiUrl: {
  "link": BaseURL + "api/account/consult/doctor/profile/"
},
userPhotoApiUrl: {
  "link": BaseURL + "api/account/consult/doctor/avatar/"
},
consultCurrenciesApiUrl: {
  "link": BaseURL + "api/account/consult/currencies/"
},
doctorCategoriesApiUrl: {
  "link": BaseURL + "api/account/consult/doctor/categories/"
},
doctorReviewsApiUrl: {
  "link": BaseURL + "api/account/consult/doctor/reviews/"
},
doctorScheduleGetApiUrl: {
  "link": BaseURL + "api/account/consult/schedule/retrieve/"
},
doctorSchedulePostApiUrl: {
  "link": BaseURL + "api/account/consult/schedule/"
},
doctorSchedulePatchApiUrl: {
  "link": BaseURL + "api/account/consult/schedule/partial-update/"
},
logoutApiUrl:{
  "link": BaseURL + "api/account/consult/doctors/logout/"
},
patientListApiUrl:{
  "link": BaseURL + "api/account/user/doctor-patients/"
},
patientInfoListApiUrl:{
  "link": BaseURL + "api/account/user/doctor-appointment-detail/"
},
patientMessageReplyListApiUrl:{
  "link": BaseURL + "api/account/user/get-doctor-message/"
},
patientOneTimeMessageListApiUrl:{
  "link": BaseURL + "api/account/consult/initial-messages/"
},
appointmentStatusUpdateApiUrl:{
  "link": BaseURL + "api/account/consult/appointment-confirm/"
},
// Patient Chat room Detail Api Url
patientWithChatRoomDetailApiUrl: {
    "link": BaseURL + "api/chat/chatrooms-message/"
},
OtpSendOnMobileApiUrl:{
  "link": BaseURL + "api/account/user/mobile-otp/"
},
uploadLicenseFileOfDoctorApiUrl:{
  "link" : BaseURL + "api/account/user/lic-file/"
},
videoSendToPatientApiUrl:{
  "link" : BaseURL + "api/account/consult/video_file_upload/"
},
getPaymentHistoryApiUrl:{
  "link": BaseURL + "api/account/payments/get-patient-transaction-history/"
},
}
export default root;