/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '../_helpers/consultRoots';

let consultPatientNotesApiUrl = root.consultPatientNotesApiUrl.link;
let remarksForPatientApiUrl = root.remoarkForPatientApiUrl.link;
let prescriptionForPatientApiUrl = root.prescriptionForPatientApiUrl.link;
let addPrescriptionApiUrl = root.addPrescriptionApiUrl.link;
let patientPrescriptionReCreatedApiUrl = root.patientPrescriptionReCreatedApiUrl.link;


export default {
  state: {
    getNotesOfPatientState:{},
    getRemarksOfPatientState:{},
    getPrescriptionOfPatientState:{}
  },
  mutations: {
    getNotesOfPatientMutation(state, value) {
      Vue.set(state, 'getNotesOfPatientState', value);
    },
    getRemarksOfPatientMutation(state, value) {
      Vue.set(state, 'getRemarksOfPatientState', value);
    },
    getPrescriptionOfPatientMutation(state, value) {
      Vue.set(state, 'getPrescriptionOfPatientState', value);
    }
  },
  actions: {  
    reCreatePrescriptionOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(patientPrescriptionReCreatedApiUrl, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    getNotesListOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(consultPatientNotesApiUrl + "?id=" + payload.id)
          .then((response) => {
            context.commit('getNotesOfPatientMutation', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    createNotesOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(consultPatientNotesApiUrl, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    removeNotesOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.delete(consultPatientNotesApiUrl + payload.id + '/')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    updateNotesDataOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(consultPatientNotesApiUrl + payload.data.id + '/', payload.data.data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    getRemarksListOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(remarksForPatientApiUrl + "?id=" + payload.id)
          .then((response) => {
            context.commit('getRemarksOfPatientMutation', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    createRemarksOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(remarksForPatientApiUrl, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    removeRemarksOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.delete(remarksForPatientApiUrl + payload.id + '/')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    updateRemarksDataOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(remarksForPatientApiUrl + payload.data.id + '/', payload.data.data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    getPrescriptionListOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.get(prescriptionForPatientApiUrl + "?appointment=" + payload.id)
          .then((response) => {
            context.commit('getPrescriptionOfPatientMutation', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    createPrescriptionOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(addPrescriptionApiUrl, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    removePrescriptionOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.delete(addPrescriptionApiUrl + payload.id + '/')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    updatePrescriptionDataOfPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.patch(addPrescriptionApiUrl + payload.data.id + '/', payload.data.data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    }
  },
  getters: {
    getNotesOfPatientGetters : state => state.getNotesOfPatientState,
    getRemarksOfPatientGetters : state => state.getRemarksOfPatientState,
    getPrescriptionOfPatientGetters : state => state.getPrescriptionOfPatientState
  }
}