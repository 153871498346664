/*eslint-disable */

import axios from 'axios';
import router from '../route';
import store from '../store/store';
import toast from './toast'

// Interceptor
axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
  let errorcode = error.response.data;
    if(errorcode != undefined){
        if(error.response.status == 400 || error.statusText == 'Bad Request'){
          if(error.response.data.code == 1000){
            if(error.response.data.data.email){
              toast.error(error.response.data.data.email[0], 'Email');
            } else if(error.response.data.data.password){
              toast.error(error.response.data.data.password.msg, 'Password');
            } else if(error.response.data.data.profile.mobile){
              toast.error(error.response.data.data.profile.mobile[0], 'Mobile');
            }else{
              toast.error('something goes wrong, Try again later')
            } 
          } else if(error.response.data.non_field_errors){
            toast.error(error.response.data.non_field_errors);
          }
        }
        if (error.response.status == 401 ||  error.statusText == 'Unauthorized') {
          toast.error('Your session expired, please login again');
          store.dispatch('logout'); 
           router.push('/');
        }
        if (error.response.status == 403 || error.response.data.code == 8003) {
              toast.error(error.response.data.msg || error.response.data.detail);
              store.dispatch('logout');
              router.push('/');
          }
        if(error.response.status == 415){
          toast.error('Media not supported');
        }
        return Promise.reject(error.response);
    }else{

    }
});